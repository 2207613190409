// src/components/CategoryButtons.js
import React from 'react';

function CategoryButtons({ currentCategory, handleCategoryChange }) {
  return (
    <div className="top-buttons">
      <button
        className={`pill-button ${currentCategory === 'new' ? 'active' : ''}`}
        onClick={() => handleCategoryChange('new')}
      >
        New
      </button>
      <button
        className={`pill-button ${
          currentCategory === 'completed' ? 'active' : ''
        }`}
        onClick={() => handleCategoryChange('completed')}
      >
        Completed
      </button>
      <button
        className={`pill-button ${
          currentCategory === 'review' ? 'active' : ''
        }`}
        onClick={() => handleCategoryChange('review')}
      >
        Review
      </button>
    </div>
  );
}

export default CategoryButtons;
