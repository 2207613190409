// src/defaultWords.js
const defaultWords = [
  {
      id: 1,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(kō)',
      japaneseWord: 'こう',
      meaning: 'Company',
      mnemonics: [
          "What is the word company in short for? 'Co'."
      ]
  },
  {
      id: 2,
      category: 'new',
      englishPronunciation: '(ginkō)',
      japaneseWord: 'ぎんこう',
      meaning: 'Bank',
      mnemonics: [
          'Ginkoin -> Gain - Co',
          'Gain - Gain/Increase money',
          'Co - Company',
          "'Gain-co' is a company that gains/increases silver. In other words, Bank."
      ]
  },
  {
      id: 3,
      category: 'new',
      englishPronunciation: '(ginkoin)',
      japaneseWord: 'ぎんこういん',
      meaning: 'Bank Employee / Banker',
      mnemonics: [
          'Ginkoin -> Gain - Co - In',
          'Gain - Gain/Increase money',
          'Co - Company',
          'In - Inside',
          'Gain-co-in employee inside a company that gains/increases money. In other words, Bank employee.'
      ]
  },
  {
      id: 4,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Kaisha)',
      japaneseWord: 'かいしゃ',
      meaning: 'Company',
      mnemonics: [
          'Split kaisha to "Kai" and "Sha" which sound like "Key" and "Share"',
          'Think of "Key" as an idea. Idea is the key to future',
          'What is a place where key ideas get shared and made into reality? A Company'
      ]
  },
  {
      id: 5,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Kaishain)',
      japaneseWord: 'かいしゃいん',
      meaning: 'Company Employee',
      mnemonics: [
          'Split kaisha to "Kai" and "Sha" "In" which sound like "Key" "Share" "In"',
          'Think of "Key" as an idea. Idea is the key to future',
          'What is a place where key ideas get shared and made into reality? A Company',
          '"In" -> as "Inside". Who are inside a Kaisha(key share)? Employees',
          "So, Kaishain is people inside where key ideas get shared i.e. Company Employee"
      ]
  },
  {
      id: 6,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Watashi)',
      japaneseWord: 'わたし',
      meaning: 'I / Me',
      mnemonics: [
          '"Wa-Ta-Shi" sounds like "What a She"',
          'わ (wa) - Imagine saying "Wa!" in surprise, like "Wow!"',
          'た (ta) - Think of "Ta" as a short form for "ta-da!" when presenting yourself.',
          'し (shi) - Sounds like "she," referring to yourself.',
          'Wa-Ta-Shi = "Wa! Ta-da! She" - You introduce yourself confidently as "I/me.'
      ]
  },
  {
      id: 7,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Anata)',
      japaneseWord: 'あなた',
      meaning: 'You',
      mnemonics: [
          '"Anata Breakdown -> A-Na-Ta"',
          'A (あ) – "Anna""',
          'Na (な) – "Name"',
          'Ta (た) – "Tag"',
          'Mnemonic: "Anna tags you by name."'
        ]
  },
  {
      id: 8,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Hito)',
      japaneseWord: 'ひと',
      meaning: 'Person',
      mnemonics: [
          '"Hito Breakdown -> Hi-To"',
          'ひ (hi) – "He"',
          'と (to) – "Tows"',
          'Mnemonic: "He tows people."'
      ]
  },
  {
      id: 9,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Anohito)',
      japaneseWord: 'あのひと',
      meaning: '"that person" or can be used as "he/she."',
      mnemonics: [
          '"Anohito Breakdown -> A-No-Hito"',
          'あ (a) – "A"',
          'の (no) – "Known"',
          'ひと (hito) – "Person"',
          'Mnemonic: "A known person"'
      ]
  },
  {
      id: 10,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(-San)',
      japaneseWord: 'さん',
      meaning: '"Mr./Ms."',
      mnemonics: [
          '"San Breakdown -> Sa-n"',
          'さ (sa) – "Sir"',
          'ん (n) – "and"',
          'Mnemonic: "Sir and noble person."'
      ]
  },
  {
      id: 11,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(-Chan)',
      japaneseWord: 'ちゃん',
      meaning: '"Miss" (Informal and Affectionate)',
      mnemonics: [
          '"Chan Breakdown -> Chi-yan"',
          'ち (chi) – "Cheerful"',
          'ゃん (yan) – "Young" (affectionate way to say someone is young)',
          'Mnemonic: "Cheerful and young Miss"'
      ]
  },
  {
      id: 12,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(-jin)',
      japaneseWord: 'じん',
      meaning: 'Nationality (Denote nationality when added to the end of a country’s name)',
      mnemonics: [
          '"Jin Breakdown -> Jo-in"',
          'じ (ji) – "Join"',
          'ん (n) – "Nation"',
          'Mnemonic: "Join a nation = nationality"'
      ]
  },
  {
      id: 13,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(sensei)',
      japaneseWord: 'せんせい',
      meaning: 'Teacher (Mentor)',
      mnemonics: [
          '"Sensei Breakdown -> Sen-say"',
          'せん (sen) – "Send"',
          'せい (sei) – "Sayings"',
          'Mnemonic: "A teacher sends sayings of wisdom.'
      ]
  },
  {
      id: 14,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(kyoushi)',
      japaneseWord: 'きょうし',
      meaning: '"teacher," often used to describe oneself as a teacher, or in job titles.',
      mnemonics: [
          '"Kyoushi Breakdown -> Key-oushi"',
          'き (Key) – "Key"',
          'ょうし (youshi) – "Ocean"'
      ]
  },
  {
      id: 15,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(gakusei)',
      japaneseWord: 'がくせい',
      meaning: 'Student',
      mnemonics: [
          '"Gakusei Breakdown -> ga-kusei"',
          'が (ga) – Think of "go" or "goes."',
          'くせい (kusei) - Reminds you of "school."',
          'Mnemonic: "Who goes to school? A student"'
      ]
  },
  {
      id: 16,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Isha)',
      japaneseWord: 'いしゃ',
      meaning: '"Doctor',
      mnemonics: [
          '"Isha Breakdown -> I-See A"',
          'い (i) – "I"',
          'しゃ (sha) – "See ya"',
          'Mnemonic: "I am sick, Should I see a Doctor?"'
      ]
  },
  {
      id: 17,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(Kenkyūsha)',
      japaneseWord: 'けんきゅうしゃ',
      meaning: '"Researcher',
      mnemonics: [
          '"Kenkyūsha Breakdown -> Can-you-search-a"',
          'けん (ken) – "Can"',
          'きゅう (kyū) – "You"',
          'しゃ (sha) – "Search a"',
          'Mnemonic: "Can you search a?" – "Thats what a researcher does!"'
      ]
  },
  {
      id: 18,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(daigaku)',
      japaneseWord: 'だいがく',
      meaning: 'University',
      mnemonics: [
          '"daigaku Breakdown -> Die-gradu"',
          'だい (dai) – Sounds like "die"',
          'がく (gaku) – Sounds like "Gradu" -> "graduate"',
          'Mnemonic: "You work so hard in university, you feel like you could "die to graduate!""'
      ]
  },
  {
      id: 19,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(biyouin)',
      japaneseWord: 'びょういん',
      meaning: 'hospital',
      mnemonics: [
          ' "biyouin Breakdown -> be-you-in" ',
          ' び (bi) – "Be" ',
          ' ょう (you) – "You" ',
          ' いん (in) – "Injured" ',
          ' Mnemonic: "Be you injured, you’ll end up in the hospital." '
      ]
  },
  {
      id: 21,
      category: 'new', // 'new', 'completed', 'review'
      englishPronunciation: '(dare)',
      japaneseWord: 'だれ',
      meaning: 'who',
      mnemonics: [
          ' だれ (dare) – "Dare"',
          ' Mnemonic: "Who dares to face me" '
      ]
  },
  {
      id: 22,
      category: 'new',
      englishPronunciation: '(sai)',
      japaneseWord: 'さい',
      meaning: 'age',
      mnemonics: [
          ' さい (sai) – Sounds like "Size"',
          ' Mnemonic: "Your Life Size" '
      ]
  },
  {
        id: 23,
        category: 'new',
        englishPronunciation: '(nan sai)',
        japaneseWord: 'なんさい',
        meaning: 'how old are you',
        mnemonics: [
            'なんさい (nan sai) – Sounds like "None Size"',
            'Mnemonic: "What size (age) are you? None size?"'
        ]
    },{
        id: 24,
        category: 'new',
        englishPronunciation: '(hai)',
        japaneseWord: 'はい',
        meaning: 'yes',
        mnemonics: [
            'はい (hai) – Sounds like "High"',
            'Mnemonic: "Yes, I’m on a high!"'
        ]
    },{
        id: 25,
        category: 'new',
        englishPronunciation: '(iie)',
        japaneseWord: 'いいえ',
        meaning: 'no',
        mnemonics: [
            'いいえ (iie) – Sounds like "E-A"',
            'Mnemonic: "E-A, no way!"',
        ]
    },{
        id: 26,
        category: 'new',
        englishPronunciation: '(hajime mashite)',
        japaneseWord: 'はじめまして',
        meaning: 'Nice to meet you',
        mnemonics: [
            'はじめまして (hajime mashite) – Sounds like "How’d you meet my stay?"',
            'Mnemonic: "Hello! How’d you meet me, by the way?"'
        ]
    },{
        id: 27,
        category: 'new',
        englishPronunciation: '(kara kimashita)',
        japaneseWord: 'からきました',
        meaning: 'I came from... (country)',
        mnemonics: [
            'からきました (kara kimashita) – Sounds like "Car Came Ashore"',
            'Mnemonic: "My car came ashore from this place."'
        ]
    },{
        id: 28,
        category: 'new',
        englishPronunciation: '(douzo yoroshiku onegaishimasu)',
        japaneseWord: 'どうぞよろしくおねがいします',
        meaning: 'Please treat me kindly / I look forward to working with you',
        mnemonics: [
            'どうぞ (douzo) – Sounds like "Do So," and よろしく (yoroshiku) – Sounds like "Your Wish"',
            'Mnemonic: "Do so as you wish – pleased to meet you!"'
        ]
    },{
        id: 29,
        category: 'new',
        englishPronunciation: '(shitsurei desu ga)',
        japaneseWord: 'しつれいですが',
        meaning: 'Excuse me, but… / I don’t mean to be rude, but…',
        mnemonics: [
            'しつれいですが (shitsurei desu ga) – Sounds like "She’s Too Late, This Guy,"',
            'Mnemonic: "She’s too late, this guy – excuse me, but…"'
        ]
    },{
        id: 30,
        category: 'new',
        englishPronunciation: '(onamae wa)',
        japaneseWord: 'おなまえは',
        meaning: 'What’s your name?',
        mnemonics: [
            'おなまえは (onamae wa) – Sounds like "Your name What?"',
            'Mnemonic: "Your name What? What’s your name?"'
        ]
    },{
        id: 31,
        category: 'new',
        englishPronunciation: '(kochira wa _________ san desu)',
        japaneseWord: 'こちらは _________ さんです',
        meaning: 'This is Mr./Ms. _________',
        mnemonics: [
            'こちら (kochira) – Sounds like "Coach Here, Ah!"',
            'Mnemonic: "The coach here is Mr./Ms. ________!"'
        ]
    },{
        id: 32,
        category: 'new',
        englishPronunciation: '(amerika)',
        japaneseWord: 'アメリカ',
        meaning: 'America',
        mnemonics: [
            'America'
        ]
    },{
        id: 33,
        category: 'new',
        englishPronunciation: '(igirisu)',
        japaneseWord: 'イギリス',
        meaning: 'England / the UK',
        mnemonics: [
            'イギリス (igirisu) – Sounds like "Eager Issue,"',
            'Mnemonic: "Imagine eagerly reading the latest issue from England."'
        ]
    },{
        id: 34,
        category: 'new',
        englishPronunciation: '(indo)',
        japaneseWord: 'インド',
        meaning: 'India',
        mnemonics: [
            'インド (indo) – Reminds you of "Indoor,"',
            'Mnemonic: "Picture the colorful indoor markets of India."'
        ]
    },{
        id: 35,
        category: 'new',
        englishPronunciation: '(indoneshia)',
        japaneseWord: 'インドネシア',
        meaning: 'Indonesia',
        mnemonics: [
            'インドネシア (indoneshia) – Sounds like "In the Ocean,"',
            'Mnemonic: "Imagine Indonesia as a group of islands in the ocean."'
        ]
    },{
        id: 36,
        category: 'new',
        englishPronunciation: '(banguradeshu)',
        japaneseWord: 'バングラデシュ',
        meaning: 'Bangladesh',
        mnemonics: [
            'banguradeshu'
        ]
    },{
        id: 37,
        category: 'new',
        englishPronunciation: '(kankoku)',
        japaneseWord: 'かんこく',
        meaning: 'South Korea',
        mnemonics: [
            'かんこく (kankoku) – Sounds like "Can Cook,"',
            'Mnemonic: "Imagine South Korea as a place where everyone can cook delicious dishes like kimchi."'
        ]
    },{
        id: 38,
        category: 'new',
        englishPronunciation: '(tai)',
        japaneseWord: 'タイ',
        meaning: 'Thailand',
        mnemonics: [
            'タイ (tai) – Reminds you of "Thai," as in Thai food or culture,',
            'Mnemonic: "Think of delicious Thai food as a symbol of Thailand."'
        ]
    },{
        id: 39,
        category: 'new',
        englishPronunciation: '(chuugoku)',
        japaneseWord: 'ちゅうごく',
        meaning: 'China',
        mnemonics: [
            'ちゅうごく (chuugoku) – Sounds like "Choo Go Cool,"',
            'Mnemonic: "Imagine a cool breeze as you travel by train (Choo) through China’s scenic landscapes."'
        ]
    },{
        id: 40,
        category: 'new',
        englishPronunciation: '(doitsu)',
        japaneseWord: 'ドイツ',
        meaning: 'Germany',
        mnemonics: [
            'ドイツ (doitsu) – Reminds you of "Deutsch," the German word for Germany or German,',
            'Mnemonic: "Think of the German word Deutsch when you see Doitsu."'
        ]
    },{
        id: 41,
        category: 'new',
        englishPronunciation: '(nihon)',
        japaneseWord: 'にほん',
        meaning: 'Japan',
        mnemonics: [
            'にほん (nihon) – Sounds like "Knee Home,"',
            'Mnemonic: "Imagine kneeling down in your home, a traditional Japanese gesture."'
        ]
    },{
        id: 42,
        category: 'new',
        englishPronunciation: '(burajiru)',
        japaneseWord: 'ブラジル',
        meaning: 'Brazil',
        mnemonics: [
            'burajiru'
        ]
    },{
        id: 43,
        category: 'new',
        englishPronunciation: '(dekiru)',
        japaneseWord: 'できる',
        meaning: 'can / to be able to',
        mnemonics: [
            'できる (dekiru) – Sounds like "Day Care,"',
            'Mnemonic: "Imagine that you’re capable of taking care of things, like in a day care."'
        ]
    },{
        id: 44,
        category: 'new',
        englishPronunciation: '(rei)',
        japaneseWord: 'れい',
        meaning: 'zero / example / politeness',
        mnemonics: [
            'れい (rei) – Sounds like "Ray,"',
            'Mnemonic: "Imagine a ray of light shining down, making everything start at zero."'
        ]
    },{
        id: 45,
        category: 'new',
        englishPronunciation: '(ichi)',
        japaneseWord: 'いち',
        meaning: 'one',
        mnemonics: [
            'いち (ichi) – Sounds like "Each,"',
            'Mnemonic: "Think of each person standing alone as number one."'
        ]
    },{
        id: 46,
        category: 'new',
        englishPronunciation: '(ni)',
        japaneseWord: 'に',
        meaning: 'two',
        mnemonics: [
            'に (ni) – Sounds like "Knee,"',
            'Mnemonic: "Imagine two knees side by side – the pair represents the number two."'
        ]
    },{
        id: 47,
        category: 'new',
        englishPronunciation: '(san)',
        japaneseWord: 'さん',
        meaning: 'three',
        mnemonics: [
            'さん (san) – Sounds like "Sun,"',
            'Mnemonic: "Imagine three suns rising in the sky, representing the number three."'
        ]
    },{
        id: 48,
        category: 'new',
        englishPronunciation: '(shi / yon)',
        japaneseWord: 'し / よん',
        meaning: 'four',
        mnemonics: [
            'し (shi) – Sounds like "She," and よん (yon) – Sounds like "Yawn,"',
            'Mnemonic: "‘She yawns’ for four seconds."'
        ]
    },{
        id: 49,
        category: 'new',
        englishPronunciation: '(go)',
        japaneseWord: 'ご',
        meaning: 'five',
        mnemonics: [
            'ご (go) – Connect it with "Five Fingers" on one hand.',
            'Mnemonic: "Picture your five fingers giving a thumbs-up, ready to ‘Go!’"'
        ]
    },{
        id: 50,
        category: 'new',
        englishPronunciation: '(roku)',
        japaneseWord: 'ろく',
        meaning: 'six',
        mnemonics: [
            'ろく (roku) – Think of the "Six Sides" of a Rubik’s Cube.',
            'Mnemonic: "Imagine each of the six sides of the Rubik’s Cube ‘rocking’ different colors!"'
        ]
    },{
        id: 51,
        category: 'new',
        englishPronunciation: '(shichi / nana)',
        japaneseWord: 'しち / なな',
        meaning: 'seven',
        mnemonics: [
            'なな (nana) – Sounds like "Nana," and think of the "Seven Colors of the Rainbow."',
            'Mnemonic: "Picture a grandmother (Nana) smiling seven days of the week."'
        ]
    },{
        id: 52,
        category: 'new',
        englishPronunciation: '(hachi)',
        japaneseWord: 'はち',
        meaning: 'eight',
        mnemonics: [
            'はち (hachi) – Think of a "Spider" with "Eight Legs."',
            'Mnemonic: "Imagine a spider crawling out of a hatch, its eight legs stretching out."'
        ]
    },{
        id: 53,
        category: 'new',
        englishPronunciation: '(kyuu / ku)',
        japaneseWord: 'きゅう / く',
        meaning: 'nine',
        mnemonics: [
            'きゅう (kyuu) – Sounds like "Queue," and think of a "Nine-to-Five" workday.',
            'Mnemonic: "Imagine lining up (queue) to start a nine-to-five day at work."'
        ]
    },{
        id: 54,
        category: 'new',
        englishPronunciation: '(juu)',
        japaneseWord: 'じゅう',
        meaning: 'ten',
        mnemonics: [
            'じゅう (juu) – Sounds like "Juice," and think of "Ten Fingers" holding a glass of juice.',
            'Mnemonic: "Picture using both hands (ten fingers) to hold a big glass of juice."'
        ]
    },{
        id: 55,
        category: 'new',
        englishPronunciation: '(serusuman)',
        japaneseWord: 'セールスマン',
        meaning: 'salesman / salesperson',
        mnemonics: [
            'serusuman'
        ]
    },{
        id: 56,
        category: 'new',
        englishPronunciation: '(kuruma)',
        japaneseWord: 'くるま',
        meaning: 'car',
        mnemonics: [
            'くるま (kuruma) – Sounds like "Car Room Ma,"',
            'Mnemonic: "Imagine a room full of cars, and your mom choosing the best one."'
        ]
    },{
        id: 57,
        category: 'new',
        englishPronunciation: '(manejaa)',
        japaneseWord: 'マネージャー',
        meaning: 'manager',
        mnemonics: [
            'manejaa'
        ]
    },{
        id: 58,
        category: 'new',
        englishPronunciation: '(ryōri-nin)',
        japaneseWord: 'りょうりにん',
        meaning: 'cook / chef',
        mnemonics: [
            'りょうり (ryōri) – "Rolling" ',
            'にん(nin) - "In"',
            'Mnemonic: "Imagine a chef skillfully rolling ingredients into dough, enclosing flavors inside."'
        ]
    },{
        id: 59,
        category: 'new',
        englishPronunciation: '(resutoran)',
        japaneseWord: 'レストラン',
        meaning: 'restaurant',
        mnemonics: [
            'Resutoran'
        ]
    },{
        id: 60,
        category: 'new',
        englishPronunciation: '(enjinia)',
        japaneseWord: 'エンジニア',
        meaning: 'engineer',
        mnemonics: [
            'enjinia'
        ]
    },{
        id: 61,
        category: 'new',
        englishPronunciation: '(dēta)',
        japaneseWord: 'データ',
        meaning: 'data',
        mnemonics: [
            'dēta'
        ]
    },{
        id: 62,
        category: 'new',
        englishPronunciation: '(shisutemu)',
        japaneseWord: 'システム',
        meaning: 'system',
        mnemonics: [
            'shisutemu'
        ]
    },{
        id: 63,
        category: 'new',
        englishPronunciation: '(puroguramu)',
        japaneseWord: 'プログラム',
        meaning: 'program',
        mnemonics: [
            'プログラム (puroguramu) – Sounds like "Pure Grammar,"',
            'Mnemonic: "Picture a language program focused on pure grammar lessons."'
        ]
    },{
        id: 64,
        category: 'new',
        englishPronunciation: '(meru)',
        japaneseWord: 'メール',
        meaning: 'email / mail',
        mnemonics: [
            'meru'
        ]
    },{
        id: 65,
        category: 'new',
        englishPronunciation: '(utsu)',
        japaneseWord: 'うつ',
        meaning: 'to type/ to hit/ depression',
        mnemonics: [
            'うつ (utsu) – Sounds like "Oops!"',
            'Mnemonic: "Imagine typing fast, then accidentally hitting a wrong key and saying, ‘Oops!’"',
            'Mnemonic: "Imagine hitting something by accident and saying ‘Oops!’ right afterward."',
            'Mnemonic: "Picture someone saying ‘Oops’ over and over, feeling stuck and unable to escape a cycle of sadness."'
        ]
    },{
        id: 66,
        category: 'new',
        englishPronunciation: '(kangoshi)',
        japaneseWord: 'かんごし',
        meaning: 'nurse',
        mnemonics: [
            'かん (kan) – Sounds like "Can," ご (go) – Reminds you of "Go," and し (shi) sounds like "She,"',
            'Mnemonic: "Picture a nurse thinking, ‘Can go she,’ as she rushes to provide care."'
        ]
    },{
        id: 68,
        category: 'new',
        englishPronunciation: '(sono)',
        japaneseWord: 'その',
        meaning: 'that',
        mnemonics: [
            'その (sono) – Reminds you of "So No,"',
            'Mnemonic: "Imagine someone exclaiming, ‘So no, that can’t be true!’ when discussing something surprising."'
        ]
    },{
        id: 69,
        category: 'new',
        englishPronunciation: '(josei)',
        japaneseWord: 'じょせい',
        meaning: 'woman',
        mnemonics: [
            'じょせい (josei) – Sounds like "Jolly Say,"',
            'Mnemonic: "Women are always jolly and say stuffs"'
        ]
    },{
        id: 70,
        category: 'new',
        englishPronunciation: '(karada)',
        japaneseWord: 'からだ',
        meaning: 'body',
        mnemonics: [
            'からだ (karada) – Sounds like "Care About Da,"',
            'Mnemonic: "Imagine someone emphasizing, ‘You should care about da body’ in a health workshop."'
        ]
    },{
        id: 71,
        category: 'new',
        englishPronunciation: '(ningen)',
        japaneseWord: 'にんげん',
        meaning: 'human',
        mnemonics: [
            'にんげん (ningen) – Sounds like "Nin Gain,"',
            'Mnemonic: "Picture a ninja gaining wisdom about humanity and the human condition."'
        ]
    },{
        id: 72,
        category: 'new',
        englishPronunciation: '(atama)',
        japaneseWord: 'あたま',
        meaning: 'head',
        mnemonics: [
            'あたま (atama) – Sounds like "A Tumor,"',
            'Mnemonic: "Imagine someone checking their head and saying, ‘A tumor would be serious!’"'
        ]
    },{
        id: 73,
        category: 'new',
        englishPronunciation: '(kega)',
        japaneseWord: 'けが',
        meaning: 'injury',
        mnemonics: [
            'けが (kega) – Sounds like "Keg,"',
            'Mnemonic: "Imagine injuring your foot while trying to kick a keg at a party!"'
        ]
    },{
        id: 74,
        category: 'new',
        englishPronunciation: '(totemo)',
        japaneseWord: 'とても',
        meaning: 'very / really',
        mnemonics: [
            'とても (totemo) – Sounds like "Toe Demos,"',
            'Mnemonic: "Picture a demonstration where someone is showing off/demoing their very impressive toe!"'
        ]
    },{
        id: 75,
        category: 'new',
        englishPronunciation: '(abunai)',
        japaneseWord: 'あぶない',
        meaning: 'dangerous',
        mnemonics: [
            'あぶない (abunai) – Sounds like "A Bruin Eye,"',
            'Mnemonic: "Think of a bear (bruin) eyeing you, making you feel like you’re in danger!"'
        ]
    },{
        id: 78,
        category: 'new',
        englishPronunciation: '(jodan)',
        japaneseWord: '冗談',
        meaning: 'joke',
        mnemonics: [
            '冗談 (jodan) – Sounds like "Joke Done,"',
            'Mnemonic: "Imagine someone saying, ‘Joke done!’ after finishing a funny story."'
        ]
    },{
        id: 79,
        category: 'new',
        englishPronunciation: '(warau)',
        japaneseWord: '笑う',
        meaning: 'to laugh',
        mnemonics: [
            '笑う (warau) – Sounds like "Wah-Rah,"',
            'Mnemonic: "Imagine someone laughing out loud saying, ‘Wah, rah, that’s so funny!’"'
        ]
    },{
        id: 80,
        category: 'new',
        englishPronunciation: '(oishii)',
        japaneseWord: 'おいしい',
        meaning: 'delicious',
        mnemonics: [
            'おいしい (oishii) – Sounds like "Oi, She,"',
            'Mnemonic: "Imagine a friend saying, ‘Oi, she makes the most delicious desserts!’"'
        ]
    },{
        id: 81,
        category: 'new',
        englishPronunciation: '(dezāto)',
        japaneseWord: 'デザート',
        meaning: 'dessert',
        mnemonics: [
            'dezāto'
        ]
    },{
        id: 82,
        category: 'new',
        englishPronunciation: '(mizu)',
        japaneseWord: '水',
        meaning: 'water',
        mnemonics: [
            '水 (mizu) – Sounds like "Miss Dew,"',
            'Mnemonic: "Think of a person saying, ‘I miss dew on the grass in the morning after the water has evaporated.’"'
        ]
    },{
        id: 83,
        category: 'new',
        englishPronunciation: '(moraemasu)',
        japaneseWord: 'もらえます',
        meaning: 'can receive / can get',
        mnemonics: [
            'もらえます (moraemasu) – Sounds like "More Amass,"',
            'Mnemonic: "Imagine receiving more and more items, saying, ‘I can amass them!’"'
        ]
    },{
        id: 84,
        category: 'new',
        englishPronunciation: '(cha)',
        japaneseWord: '茶',
        meaning: 'tea',
        mnemonics: [
            '茶 (cha) – Sounds like "Cha,"',
            'Mnemonic: "Imagine someone saying, ‘Cha! This tea is delightful!’ while sipping."'
        ]
    },{
        id: 85,
        category: 'new',
        englishPronunciation: '(kappu)',
        japaneseWord: 'カップ',
        meaning: 'cup',
        mnemonics: [
            'カップ (kappu) – Sounds like "Cup,"',
            'Mnemonic: "Imagine lifting a cup and saying, ‘This is my favorite cup!’"'
        ]
    },{
        id: 86,
        category: 'new',
        englishPronunciation: '(kōhī)',
        japaneseWord: 'コーヒー',
        meaning: 'coffee',
        mnemonics: [
            'コーヒー (kōhī) – Sounds like "Cool He,"',
            'Mnemonic: "Picture a friend saying, ‘Cool, he drinks coffee every morning!’"'
        ]
    },{
        id: 87,
        category: 'new',
        englishPronunciation: '(kan)',
        japaneseWord: '缶',
        meaning: 'can',
        mnemonics: [
            '缶 (kan) – Sounds like "Can,"',
            'Mnemonic: "Imagine saying, ‘I can hold this can of soda!’ while lifting it."'
        ]
    },{
        id: 88,
        category: 'new',
        englishPronunciation: '(bīru)',
        japaneseWord: 'ビール',
        meaning: 'beer',
        mnemonics: [
            'ビール (bīru) – Sounds like "Beer,"',
            'Mnemonic: "Imagine sitting at a bar and saying, ‘I would like a beer!’"'
        ]
    },{
        id: 89,
        category: 'new',
        englishPronunciation: '(gurasu)',
        japaneseWord: 'グラス',
        meaning: 'glass',
        mnemonics: [
            'グラス (gurasu) – Sounds like "Glass,"',
            'Mnemonic: "Imagine saying, ‘I need a glass for my drink!’ while pointing to the cabinet."'
        ]
    },{
        id: 90,
        category: 'new',
        englishPronunciation: '(wain)',
        japaneseWord: 'ワイン',
        meaning: 'wine',
        mnemonics: [
            'ワイン (wain) – Sounds like "Wine,"',
            'Mnemonic: "Imagine toasting with friends and saying, ‘Cheers to this wine!’"'
        ]
    },{
        id: 91,
        category: 'new',
        englishPronunciation: '(gyuniku)',
        japaneseWord: '牛肉',
        meaning: 'beef',
        mnemonics: [
            '牛肉 (gyuniku) – Sounds like "Give new Cow,"',
            'Mnemonic: "Imagine someone saying, ‘Give new cow meat—beef!’"'
        ]
    },{
        id: 92,
        category: 'new',
        englishPronunciation: '(toriniku)',
        japaneseWord: '鶏肉',
        meaning: 'chicken',
        mnemonics: [
            '鶏肉 (toriniku) – Sounds like "Torn a chikenu,"',
            'Mnemonic: "Picture someone saying, ‘I have torn a chickenu for dinner!’"'
        ]
    },{
        id: 93,
        category: 'new',
        englishPronunciation: '(taberu)',
        japaneseWord: '食べる',
        meaning: 'to eat',
        mnemonics: [
            '食べる (taberu) – Sounds like "Ta Bear,"',
            'Mnemonic: "Picture a bear saying, ‘Ta bear, I’m going to eat this honey!’"'
        ]
    },{
        id: 94,
        category: 'new',
        englishPronunciation: '(butaniku)',
        japaneseWord: '豚肉',
        meaning: 'pork',
        mnemonics: [
            '豚肉 (butaniku) – Sounds like "But, I Knew,"',
            'Mnemonic: "Imagine someone saying, ‘But, I knew this pork meat is Haram!’"'
        ]
    },{
        id: 95,
        category: 'new',
        englishPronunciation: '(honzuki)',
        japaneseWord: '本好き',
        meaning: 'to like books',
        mnemonics: [
            '本好き (honzuki) – Sounds like "Hone Zook (Book),"',
            'Mnemonic: "Imagine someone saying, ‘I hone my mind with every book I read!’"'
        ]
    },{
        id: 96,
        category: 'new',
        englishPronunciation: '(sakana)',
        japaneseWord: '魚',
        meaning: 'fish',
        mnemonics: [
            '魚 (sakana) – Sounds like "Stuck On A Net,"',
            'Mnemonic: "Imagine a fish saying, ‘Help! I’m stuck on a net!’ after being caught."'
        ]
    },{
        id: 97,
        category: 'new',
        englishPronunciation: '(sakanatsuri)',
        japaneseWord: '魚釣り',
        meaning: 'fishing',
        mnemonics: [
            '魚釣り (sakanatsuri) – Sounds like "Stuck On A Net, Tsu Reel,"',
            'Mnemonic: Picture someone reeling in a big catch and saying, ‘Tsu reel it in!'
        ]
    },{
        id: 98,
        category: 'new',
        englishPronunciation: '(ramuniku)',
        japaneseWord: 'ラム肉',
        meaning: 'lamb',
        mnemonics: [
            'ラム肉 (ramuniku) – "Ram Meat,"',
            'Mnemonic: "Imagine a big ram saying, ‘I’m the meat of the feast!’ when talking about lamb."'
        ]
    },{
        id: 99,
        category: 'new',
        englishPronunciation: '(kanojo)',
        japaneseWord: '彼女',
        meaning: 'she',
        mnemonics: [
            '彼女 (kanojo) – "Kan(彼) = He" + "Jo(女) = Woman,"',
            'Mnemonic: Picture "he" introducing "her" as his special woman.'
        ]
    },{
        id: 100,
        category: 'new',
        englishPronunciation: '(suki)',
        japaneseWord: '好き',
        meaning: 'like/love',
        mnemonics: [
            '好き (suki) – Sounds like "Ski,"',
            'Mnemonic: Imagine saying, "I *like* to ski!" Picture yourself skiing down a mountain you *love*.'
        ]
    },
    {
        id: 101,
        category: 'new',
        englishPronunciation: '(kakaru)',
        japaneseWord: 'かかる',
        meaning: 'to take (time or resources)',
        mnemonics: [
            'かかる (kakaru) – Sounds like "Ca Ca Roo,"',
            'Mnemonic: Imagine jumping around like a kangaroo, which *takes* a lot of energy and endurance.'
        ]
    },{
        id: 102,
        category: 'new',
        englishPronunciation: '(keisatsukan)',
        japaneseWord: '警察官',
        meaning: 'police officer',
        mnemonics: [
            '警察官 (keisatsukan) – Sounds like "Case at Scan,"',
            'Mnemonic: Picture a police officer scanning evidence for a case at a crime scene.'
        ]
    },{
        id: 103,
        category: 'new',
        englishPronunciation: '(seifuku)',
        japaneseWord: '制服',
        meaning: 'uniform',
        mnemonics: [
            '制服 (seifuku) – Sounds like "Safe Look,"',
            'Mnemonic: Imagine wearing a uniform to school because it gives you a "safe look" that fits in with everyone else.'
        ]
    },{
        id: 104,
        category: 'new',
        englishPronunciation: '(kita)',
        japaneseWord: '来た',
        meaning: 'came/arrived',
        mnemonics: [
            '来た (kita) – Sounds like "Keen to Arrive,"',
            'Mnemonic: Picture someone so eager to arrive that they say, "I’m keen to arrive early!" as they finally get there.'
        ]
    },{
        id: 105,
        category: 'new',
        englishPronunciation: '(jugyoin)',
        japaneseWord: '従業員',
        meaning: 'employee',
        mnemonics: [
            '従業員 (jugyoin) – Sounds like "Ju Go In,"',
            'Mnemonic: Imagine someone saying, "Ju, go in and get to work!" to an employee who is just starting their shift.'
        ]
    },{
        id: 106,
        category: 'new',
        englishPronunciation: '(te a te)',
        japaneseWord: '手当て',
        meaning: 'benefit / allowance / compensation / medical treatment',
        mnemonics: [
            '手当て (te a te) – "Te" (hand) + "A Tea,"',
            'Mnemonic: Imagine using your hands to brew "a tea" as a reward, symbolizing a small allowance or treat for yourself.'
        ]
    },{
        id: 107,
        category: 'new',
        englishPronunciation: '(kuru)',
        japaneseWord: '来る',
        meaning: 'to come',
        mnemonics: [
            '来る (kuru) – Sounds like "Crew,"',
            'Mnemonic: Imagine you’re excited for your crew to arrive, saying, "The crew is coming!"'
        ]
    },{
        id: 108,
        category: 'new',
        englishPronunciation: '(hayaku)',
        japaneseWord: '早く',
        meaning: 'quickly / hurry',
        mnemonics: [
            '早く (hayaku) – "Hurry! Could you?"',
            'Mnemonic: Picture someone rushing and asking for help, saying, "Hurry! Could you help me with this?" as they quickly work on a task.'
        ]
    },{
        id: 109,
        category: 'new',
        englishPronunciation: '(miru)',
        japaneseWord: '見る',
        meaning: 'to see / to look',
        mnemonics: [
            '見る (miru) – Sounds like "Mirror,"',
            'Mnemonic: Imagine looking into a mirror to see yourself clearly.'
        ]
    },{
        id: 111,
        category: 'new',
        englishPronunciation: '(kyaku)',
        japaneseWord: '客',
        meaning: 'guest / visitor',
        mnemonics: [
            '客 (kyaku) – Sounds like "Cue Queue,"',
            'Mnemonic: Picture a long queue of guests lined up, and you have to cue each visitor as they enter.'
        ]
    },{
        id: 112,
        category: 'new',
        englishPronunciation: '(yuhi)',
        japaneseWord: '夕日',
        meaning: 'sunset',
        mnemonics: [
            '夕日 (yuhi) – "You He,"',
            'Mnemonic: Imagine saying, "You, he and I are watching the beautiful sunset together."'
        ]
    },{
        id: 113,
        category: 'new',
        englishPronunciation: '(tsukuru)',
        japaneseWord: '作る',
        meaning: 'to make / to create',
        mnemonics: [
            '作る (tsukuru) – Sounds like "To Secure,"',
            'Mnemonic: Imagine creating something and saying, "I need to secure all the parts to make it perfect."'
        ]
    },{
        id: 114,
        category: 'new',
        englishPronunciation: '(ryori)',
        japaneseWord: '料理',
        meaning: 'cooking / cuisine',
        mnemonics: [
            '料理 (ryōri) – Sounds like "Raw ingREdiants,"',
            'Mnemonic: Picture someone inspecting fresh, raw ingredients saying, "Raw ingrediants is the key to great cooking!"'
        ]
    },{
        id: 115,
        category: 'new',
        englishPronunciation: '(tsukau)',
        japaneseWord: '使う',
        meaning: 'to use',
        mnemonics: [
            '使う (tsukau) – Sounds like "Tsuk" + "Cow,"',
            'Mnemonic: Picture a cow that needs to be "used" for a task on the farm, like pulling a plow.'
        ]
    },{
        id: 116,
        category: 'new',
        englishPronunciation: '(densha)',
        japaneseWord: '電車',
        meaning: 'train',
        mnemonics: [
            '電車 (densha) – Sounds like "Dance-a,"',
            'Mnemonic: Picture the train dancing to the rhythm of the tracks'
        ]
    },{
        id: 117,
        category: 'new',
        englishPronunciation: '(kyo)',
        japaneseWord: '今日',
        meaning: 'today',
        mnemonics: [
            '今日 (kyō) – Sounds like "Key O,"',
            'Mnemonic: Imagine saying, "Today is the key (kyo) to opening up new opportunities!"'
        ]
    },{
        id: 118,
        category: 'new',
        englishPronunciation: '(kino)',
        japaneseWord: '昨日',
        meaning: 'yesterday',
        mnemonics: [
            '昨日 (kinō) – Sounds like "Key No,"',
            'Mnemonic: Imagine saying, "Yesterday was the key, no?" as you reflect on something important that happened.'
        ]
    },{
        id: 119,
        category: 'new',
        englishPronunciation: '(gogo)',
        japaneseWord: '午後',
        meaning: 'afternoon / PM',
        mnemonics: [
            '午後 (gogo) – Sounds like "Go Goal,"',
            'Mnemonic: Picture setting an afternoon children playing footbal and scored goal'
        ]
    },{
        id: 120,
        category: 'new',
        englishPronunciation: '(ashita)',
        japaneseWord: '明日',
        meaning: 'tomorrow',
        mnemonics: [
            '明日 (ashita) – "Ash" + "Ta,"',
            'Mnemonic: Picture saying, "The ashes of today turn to new beginnings tomorrow."'
        ]
    },{
        id: 121,
        category: 'new',
        englishPronunciation: '(mata)',
        japaneseWord: 'また',
        meaning: 'again / also',
        mnemonics: [
            'また (mata) – "Mat" as in "meet" + "A" for "again,"',
            'Mnemonic: Imagine a welcome mat where you and a friend always meet, saying, "Let’s meet at the mat again!"'
        ]
    },{
        id: 123,
        category: 'new',
        englishPronunciation: '(shu)',
        japaneseWord: '週',
        meaning: 'week',
        mnemonics: [
            '週 (shu) – "Shu" as in "Schedule,"',
            'Mnemonic: Think of a weekly schedule where you have activities planned for every day of the week.'
        ]
    },{
        id: 124,
        category: 'new',
        englishPronunciation: '(toshi)',
        japaneseWord: '年',
        meaning: 'year',
        mnemonics: [
            '年 (toshi) – Sounds like "Toast She,"',
            'Mnemonic: Imagine toasting to someone at a New Year’s celebration, saying, "Here’s to a great year for her!"'
        ]
    },{
        id: 125,
        category: 'new',
        englishPronunciation: '(urudoshi)',
        japaneseWord: '閏年',
        meaning: 'leap year',
        mnemonics: [
            '閏年 (urudoshi) – Sounds like "You’re Due, Oh She,"',
            'Mnemonic: Imagine someone saying, "You’re due for an extra day, oh she," as a reminder that it’s a leap year with an extra day!'
        ]
    },{
        id: 126,
        category: 'new',
        englishPronunciation: '(byo)',
        japaneseWord: '秒',
        meaning: 'second',
        mnemonics: [
            '秒 (byō) – Sounds like "Bee Oh,"',
            'Mnemonic: Imagine a bee quickly flying by, so fast that it takes only a second to pass, saying "Bee, oh, that was quick!"'
        ]
    },{
        id: 127,
        category: 'new',
        englishPronunciation: '(pun)',
        japaneseWord: '分',
        meaning: 'minute',
        mnemonics: [
            '分 (pun) – Sounds like "Pawn,"',
            'Mnemonic: Picture a pawn on a chessboard moving, and you think, "I’ll just make this move in a minute."'
        ]
    },{
        id: 128,
        category: 'new',
        englishPronunciation: '(jikan)',
        japaneseWord: '時間',
        meaning: 'time / hour',
        mnemonics: [
            '時間 (jikan) – Sounds like "Gee Can,"',
            'Mnemonic: Imagine someone looking at their watch and saying, "Gee, can you believe how much time has passed?"'
        ]
    },
    {
        id: 129,
        category: 'new',
        englishPronunciation: '(tokei)',
        japaneseWord: '時計',
        meaning: 'clock / watch',
        mnemonics: [
            '時計 (tokei) – Sounds like "Token,"',
            'Mnemonic: Imagine using a token to operate a public clock, saying, "This token keeps the clock ticking!"'
        ]
    },{
        id: 130,
        category: 'new',
        englishPronunciation: '(mezamashidokei)',
        japaneseWord: '目覚まし時計',
        meaning: 'alarm clock',
        mnemonics: [
            '目覚まし時計 (mezamashidokei) – Sounds like "Maze Mashy Do Key,"',
            'Mnemonic: Imagine an alarm clock in the shape of a maze, with a key that mashes buttons to wake you up with loud, maze-like sounds.'
        ]
    },
    {
        id: 131,
        category: 'new',
        englishPronunciation: '(ji)',
        japaneseWord: '時',
        meaning: 'hour / o\'clock',
        mnemonics: [
            '時 (ji) – Sounds like "G,"',
            'Mnemonic: Picture a clock shaped like the letter "G," and every hour it chimes with a "G" sound to tell you the time.'
        ]
    },{
        id: 132,
        category: 'new',
        englishPronunciation: '(karenda)',
        japaneseWord: 'カレンダー',
        meaning: 'calendar',
        mnemonics: [
            'karenda'
        ]
    },{
        id: 133,
        category: 'new',
        englishPronunciation: '(jibun-tachi)',
        japaneseWord: '自分たち',
        meaning: 'ourselves / ourselves as a group',
        mnemonics: [
            '自分たち (jibun-tachi) – "Gee Bun Touchy,"',
            'Mnemonic: Imagine a group of friends hugging each other and saying, "Gee, we’re all so touchy with ourselves!" as they celebrate together.'
        ]
    },{
        id: 134,
        category: 'new',
        englishPronunciation: '(getsuyobi)',
        japaneseWord: '月曜日',
        meaning: 'Monday',
        mnemonics: [
            '月曜日 (getsuyōbi) – Sounds like "Gets You Busy,"',
            'Mnemonic: Imagine thinking, "Monday gets you busy," as the week kicks off with lots of tasks.'
        ]
    },{
        id: 135,
        category: 'new',
        englishPronunciation: '(shigoto)',
        japaneseWord: '仕事',
        meaning: 'work / job',
        mnemonics: [
            '仕事 (shigoto) – Sounds like "She Go To,"',
            'Mnemonic: Imagine someone saying, "She goes to work every day," as they watch her heading off to her job.'
        ]
    },{
        id: 136,
        category: 'new',
        englishPronunciation: '(kara)',
        japaneseWord: 'から',
        meaning: 'from / because',
        mnemonics: [
            'から (kara) – "Ka" + "Ra,"',
            'Mnemonic: Think of someone saying, "Ka-ra is from a distant land," emphasizing the place or source.'
        ]
    },{
        id: 137,
        category: 'new',
        englishPronunciation: '(hajimaru)',
        japaneseWord: '始まる',
        meaning: 'to begin / to start',
        mnemonics: [
            '始まる (hajimaru) – Sounds like "Ha, Gym Are You,"',
            'Mnemonic: Imagine someone about to start a workout, saying, "Ha! Gym, are you ready to begin?" as they prepare.'
        ]
    },{
        id: 138,
        category: 'new',
        englishPronunciation: '(raishu)',
        japaneseWord: '来週',
        meaning: 'next week',
        mnemonics: [
            '来週 (raishū) – Sounds like "Rice Shoe,"',
            'Mnemonic: Imagine wearing shoes made of rice, and you say, "I’ll wear my rice shoes next week!" as you look forward to a future event.'
        ]
    },{
        id: 139,
        category: 'new',
        englishPronunciation: '(kayobi)',
        japaneseWord: '火曜日',
        meaning: 'Tuesday',
        mnemonics: [
            '火曜日 (kayōbi) – "Kay, Yo Bee" like "Okay, Yo, Be Ready for Taco tuesday,"',
            'Mnemonic: Picture yourself looking forward to "Taco Tuesday," thinking, "Kay, yo bee ready for tacos on 火曜日 (Tuesday)!"'
        ]
    },{
        id: 140,
        category: 'new',
        englishPronunciation: '(nichi)',
        japaneseWord: '日',
        meaning: 'day',
        mnemonics: [
            '日 (nichi) – Sounds like "Knee Chee,"',
            'Mnemonic: Imagine that every day you bend your knees to start fresh, thinking, "It’s knee-chee, a new day."'
        ]
    },{
        id: 141,
        category: 'new',
        englishPronunciation: '(suiyobi)',
        japaneseWord: '水曜日',
        meaning: 'Wednesday',
        mnemonics: [
            '水曜日 (suiyōbi) – Sounds like "Sui" for "Suit,"',
            'Mnemonic: Picture yourself putting on a suit for all the midweek meetings, thinking, "It’s Suit Day (水曜日) on Wednesday!"'
        ]
    },{
        id: 142,
        category: 'new',
        englishPronunciation: '(mikka)',
        japaneseWord: '三日',
        meaning: 'third day / three days',
        mnemonics: [
            '三日 (mikka) – Sounds like "Milk-a,"',
            'Mnemonic: Imagine having a milk carton that lasts for three days, so each day you say, "This is my *milk-a* for day three."'
        ]
    },
    {
        id: 143,
        category: 'new',
        englishPronunciation: '(mokuyobi)',
        japaneseWord: '木曜日',
        meaning: 'Thursday',
        mnemonics: [
            '木曜日 (mokuyōbi) – Sounds like "Mock You Bee,"',
            'Mnemonic: Picture a bee poking fun with old memories on #ThrowbackThursday, saying, "I’m here to mock your old photos, it’s mokuyōbi!"'
        ]
    },{
        id: 144,
        category: 'new',
        englishPronunciation: '(yatta)',
        japaneseWord: 'やった',
        meaning: 'I did it! / Hooray!',
        mnemonics: [
            'やった (yatta) – Sounds like "Yacht Ta,"',
            'Mnemonic: Imagine achieving something big, like winning a yacht, and shouting, "Yacht, ta-da! I did it!"'
        ]
    },{
        id: 145,
        category: 'new',
        englishPronunciation: '(kinyobi)',
        japaneseWord: '金曜日',
        meaning: 'Friday',
        mnemonics: [
            '金曜日 (kinyōbi) – "Kin" sounds like "King," and Fridays feel like the king of days as they start the weekend,',
            'Mnemonic: Imagine Friday as the king of days, saying, "I’m King Yo Bee!" celebrating the start of the weekend.'
        ]
    },{
        id: 146,
        category: 'new',
        englishPronunciation: '(doyobi)',
        japaneseWord: '土曜日',
        meaning: 'Saturday',
        mnemonics: [
            '土曜日 (doyōbi) – "Do Yo Bee,"',
            'Mnemonic: Picture a bee saying, "Do yo’ thing on Saturday!" since it’s a day for doing whatever you like.'
        ]
    },{
        id: 147,
        category: 'new',
        englishPronunciation: '(yoru)',
        japaneseWord: '夜',
        meaning: 'night',
        mnemonics: [
            '夜 (yoru) – Sounds like "Yo, Rest Up," for night,',
            'Mnemonic: Imagine saying, "Yo, rest up!" every night as part of your routine, linking *yoru* with the time to sleep.'
        ]
    },{
        id: 148,
        category: 'new',
        englishPronunciation: '(suru)',
        japaneseWord: 'する',
        meaning: 'to do / to make',
        mnemonics: [
            'する (suru) – "Sew Routine," as in creating habits,',
            'Mnemonic: Imagine sewing (like creating) a routine, saying, "I sew my routine every day," making *suru* relate to daily actions.'
        ]
    },{
        id: 149,
        category: 'new',
        englishPronunciation: '(kaji)',
        japaneseWord: '家事',
        meaning: 'housework / household chores',
        mnemonics: [
            '家事 (kaji) – Sounds like "Catchy,"',
            'Mnemonic: Imagine singing a catchy tune while doing house chores, saying, "It’s *kaji* time with this catchy tune!" to make chores more enjoyable.'
        ]
    },{
        id: 150,
        category: 'new',
        englishPronunciation: '(iku)',
        japaneseWord: '行く',
        meaning: 'to go',
        mnemonics: [
            '行く (iku) – "I Queue," like standing in a line to go somewhere,',
            'Mnemonic: Imagine waiting in a queue and thinking, "I queue to go," reminding you that *iku* means to go.'
        ]
    }

    
    


  // Add more words here
  ];
  
  export default defaultWords;
  