// src/App.js
import React, { useState, useEffect, useCallback } from 'react';
import Header from './components/Header';
import Flashcard from './components/Flashcard';
import CategoryButtons from './components/CategoryButtons';
import defaultWords from './defaultWords';
import {
  auth,
  db,
  provider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  doc,
  getDoc,
  setDoc,
} from './firebaseConfig';
import './App.css';

function App() {
  // State variables
  const [words, setWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCategory, setCurrentCategory] = useState('new');
  const [userEmail, setUserEmail] = useState('');
  const [user, setUser] = useState(null);

  // Load user data from Firestore
  const loadUserData = useCallback(
    async (email) => {
      try {
        const userDoc = doc(db, 'users', email);
        const docSnap = await getDoc(userDoc);
        if (docSnap.exists()) {
          setWords(docSnap.data().words);
        } else {
          // If no data exists, initialize with default words
          setWords(defaultWords);
          saveUserData(defaultWords);
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    },
    [db]
  );

  // Save user data to Firestore
  const saveUserData = async (wordsData) => {
    try {
      const userDoc = doc(db, 'users', userEmail);
      await setDoc(userDoc, { words: wordsData });
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  // Authentication handlers
  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Sign-in error:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setWords(defaultWords);
      setCurrentIndex(0);
    } catch (error) {
      console.error('Sign-out error:', error);
    }
  };

  // Word manipulation functions
  const getWordsByCategory = (category) => {
    return words.filter((word) => word.category === category);
  };

  const loadWord = () => {
    const categoryWords = getWordsByCategory(currentCategory);
    if (categoryWords.length === 0) {
      return null;
    }
    if (currentIndex >= categoryWords.length) {
      setCurrentIndex(0);
    }
    return categoryWords[currentIndex];
  };

  const handleNextWord = () => {
    const categoryWords = getWordsByCategory(currentCategory);
    if (categoryWords.length === 0) {
      alert('No more words in this category.');
      return;
    }
    // Mark current word as completed
    const updatedWords = words.map((word) =>
      word.id === categoryWords[currentIndex].id
        ? { ...word, category: 'completed' }
        : word
    );
    setWords(updatedWords);
    saveUserData(updatedWords);

    // Move to next word
    setCurrentIndex((prevIndex) => (prevIndex + 1) % categoryWords.length);
  };

  const handleReviewLater = () => {
    const categoryWords = getWordsByCategory(currentCategory);
    if (categoryWords.length === 0) {
      alert('No more words in this category.');
      return;
    }
    // Mark current word as review
    const updatedWords = words.map((word) =>
      word.id === categoryWords[currentIndex].id
        ? { ...word, category: 'review' }
        : word
    );
    setWords(updatedWords);
    saveUserData(updatedWords);

    // Move to next word
    setCurrentIndex((prevIndex) => (prevIndex + 1) % categoryWords.length);
  };

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
    setCurrentIndex(0);
  };

  // Authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
        setUser(user);
        loadUserData(user.email);
      } else {
        setUserEmail('');
        setUser(null);
        setWords(defaultWords);
      }
    });

    return () => unsubscribe();
  }, [loadUserData]);

  const currentWord = loadWord();

  return (
    <div className="app">
      <Header
        user={user}
        handleSignIn={handleSignIn}
        handleSignOut={handleSignOut}
      />
      <main>
        <CategoryButtons
          currentCategory={currentCategory}
          handleCategoryChange={handleCategoryChange}
        />
        <Flashcard
          word={currentWord}
          handleNextWord={handleNextWord}
          handleReviewLater={handleReviewLater}
          user={user} // Pass the user prop here
        />
      </main>
    </div>
  );
}

export default App;
