// src/components/Flashcard.js
import React, { useState, useEffect } from 'react';

function Flashcard({ word, handleNextWord, handleReviewLater, user }) {
  const [showMeaning, setShowMeaning] = useState(false);
  const [showMnemonics, setShowMnemonics] = useState(false);

  // Reset local state when word changes
  useEffect(() => {
    setShowMeaning(false);
    setShowMnemonics(false);
  }, [word]);

  if (!word) {
    return (
      <div className="flashcard">
        <div className="flashcard-box" id="word-box">
          <p>
            No words available.
            <br />
            Check "Completed" or "Review" page.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flashcard">
        {/* Word Box */}
        <div className="flashcard-box" id="word-box">
          <p id="english-pronunciation">{word.englishPronunciation}</p>
          <p id="japanese-word">{word.japaneseWord}</p>
        </div>

        {/* Meaning Box */}
        <div className="flashcard-box" id="meaning-box">
          {!showMeaning ? (
            <button
              id="show-meaning-button"
              onClick={() => setShowMeaning(true)}
            >
              Show Meaning
            </button>
          ) : (
            <p id="meaning-text">{word.meaning}</p>
          )}
        </div>

        {/* Mnemonics Box */}
        <div className="flashcard-box mnemonics-box" id="mnemonics-box">
          {!showMnemonics ? (
            <button
              id="show-mnemonics-button"
              onClick={() => setShowMnemonics(true)}
            >
              Show Mnemonics
            </button>
          ) : (
            <div id="mnemonics-text">
              {word.mnemonics.map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Conditionally render the sign-in instruction */}
      {!user && (
        <div className="sign-in-instruction">
          Sign-In To Track Your Completed Words
        </div>
      )}

      {/* Bottom Buttons */}
      <div className="bottom-buttons">
        <button id="review-later-button" onClick={handleReviewLater}>
          Need to Review Later
        </button>
        <button id="next-word-button" onClick={handleNextWord}>
          Completed. Next Word
        </button>
      </div>
    </>
  );
}

export default Flashcard;
