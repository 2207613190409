// src/components/Header.js
import React from 'react';

function Header({ user, handleSignIn, handleSignOut }) {
  return (
    <header>
      <div className="header-left">
        <h6>
          Japanese
          <br />
          Mnemonics.com
        </h6>
      </div>
      <nav className="header-center">
        {/* Replace '#' with valid hrefs or remove href if not navigating */}
        <a href="/" id="hiragana-link">
          Hiragana
        </a>
        <a href="/" id="katakana-link">
          Katakana
        </a>
        <a href="/" id="vocabulary-link">
          Vocabulary
        </a>
      </nav>
      <div className="header-right">
        {!user ? (
          <div id="signin-button" onClick={handleSignIn}>
            Sign In
          </div>
        ) : (
          <div id="signout-button" onClick={handleSignOut}>
            Sign Out
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
